@import '../../../variables';

.plotItem {
  display: flex;
  justify-content: space-between;
  background: $bg-color-main;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: $addition-shadow;

  li {
    line-height: 1.3;
  }
}

.plot {
  background: #deb0b0;
  width: 120px;
  height: 120px;
  transition: all .21s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.drawBtn {
  display: inline-block;
  color: $text-color-additional;
  padding: 5px 20px;
  background: $main-color;
  border-radius: 16px;
  margin-top: 10px;
  transition: all .21s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}
