div.plots {
  margin-top: 20px;
  padding: 20px 30px;
}

.emptyList {
  text-align: center;
}

.buyBtn {
  display: inline-block;
  margin-top: 20px;
}
