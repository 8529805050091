:global {
  #root {
    height: 100%;
  }

  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1 0 auto;
  }

  .wrapper {
    max-width: 800px;
    padding: 0 30px;
    margin: 0 auto;
  }

  .standard-btn {
    font-size: $standard-fz;
    color: $text-color-additional;
    background: $main-color;
    padding: 8px 18px;
    border-radius: 18px;
    border: none;
    cursor: pointer;
    transition: transform .21s ease-in-out;

    &:hover {
      transform: scale(1.06);
    }
  }

  // popup
  .popup-overlay {
    backdrop-filter: blur(5px);
    cursor: pointer;
  }

  .popup-content {
    background: transparent;
  }
}


