@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Source+Sans+Pro&display=swap');

// fonts
$main-font: 'Source Sans Pro', sans-serif;
$custom-font: 'Josefin Sans', sans-serif;
$standard-fz: 16px;
$logo-size: 28px;

// colors
$main-color: #7B3FE4;
$addition-color: #a581ec;
$text-color-additional: #FFF;
$text-color-additional-2: #937676;

$bg-color-main: rgba(123, 63, 228, .05);

// shadows
$main-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
$addition-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
