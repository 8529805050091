@import '../../variables';
@import '../../global';

.footer {
  font-size: 15px;
  padding: 10px 0;
  width: 100%;
  box-shadow: $main-shadow;
  flex-shrink: 0;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftFooter {
  display: flex;
  flex-direction: row;

}

.rulesBtn {
  color: $main-color;
  font-size: 16px;
  font-weight: 600;
  background: none;
  cursor: pointer;
  padding: 2px 4px;
  margin: 0;
}

.rules {
  background: transparent;
}

.rulesContent {
  background: rgb(255, 255, 255);
  padding: 25px;
  border-radius: 4px;
  box-shadow: $main-shadow;
  cursor: pointer;
  text-align: left;
  animation: showPopup .21s linear;

  &.animationClose {
    animation: hidePopup .21s linear;
    animation-fill-mode: forwards;
  }
}

.rulesList {
  list-style: none;
  margin-top: 15px;

  li {
    padding: 4px 0;

    &:before {
      content: '👉';
    }
  }
}

.rulesContent > h2 {
  text-align: center;
  color: $main-color;
}

@keyframes showPopup {
  0% {
    transform: scale(.55) translate(100%, 185%);
    opacity: .5;
    filter: blur(8px);
  }
  65% {
    transform: scale(.75);
    opacity: .7;
  }
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes hidePopup {
  0% {
    transform: scale(1) translate(0, 0);
    filter: blur(0);
  }
  65% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.55) translate(100%, 185%);
    filter: blur(8px);
  }
}

.etherscanlogo {
  height: 18px;
  margin-left: 18px;
  margin-right: 18px;
}
