@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html, body {
  height: 100%;
}

body {
  font-family: $main-font;
  font-size: $standard-fz;
  position: relative;
}

ul, ol {
  list-style: none;
}

button {
  font-family: $main-font;
  font-size: $standard-fz;
  //border: none;

  &:active {
    outline: none;
    border: none;
  }
}

a {
  text-decoration: none;
}
