@import '../../variables';

#canvas {
  background: $bg-color-main;
  border-radius: 6px;
  width: calc(100vh - 100px);
  height: calc(100vh - 100px);
  box-shadow: 6px 6px 25px rgba(0, 0, 0, .1);
  padding: 0;
  margin: auto;
  display: block;
  position: relative;
  cursor: grab;
  border: 1px solid $main-color;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 180px);
  }

}

.loading {
  display: flex;
  justify-content: center;
  font-size: 36px;
  margin-top: 36px;
  color: $main-color
}

.resetButton {
  padding: 3px;
  border-radius: 3px;
  border: 2px solid $main-color;
  top: 10px;
  left: 5px;
  position: fixed;
  font-size: 18px;

  @media screen and (max-width: 1024px) {
      display: none;
  }
}