@import '../../variables';
@import '../../global';

.header {
  padding: 9px 0;
  box-shadow: $main-shadow;
}

.linkContainerMobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    column-gap: 5px;
    padding-left: 5px;
    padding-top: 3px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 768px) {
      .linkContainer {
        display: none;
      }
  
      :global(.standard-btn) {
        padding: 3px;
      }
    }
}

.logo {
  display: flex;
  font-family: $custom-font;
  font-size: $logo-size;
  color: $main-color;
}

.headerLink {
  margin-right: 18px;
}

.letter {
  background: $main-color;
  color: $text-color-additional;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

a.disabled, a.disabled:visited  {
  color: gray;
}

.rightSection {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 5px;
  align-items: center;
}

